body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 * Override global Material UI styles
 */
.MuiListItem-divider:last-child {
  border-bottom: none;
}
p.MuiTypography-root {
  margin-bottom: 12px;
}

/**
 * Remove cross icon on search input
 */
 input[type="search"]::-webkit-search-decoration,
 input[type="search"]::-webkit-search-cancel-button,
 input[type="search"]::-webkit-search-results-button,
 input[type="search"]::-webkit-search-results-decoration {
   display: none;
 }

/**
 * Disable iOS zoom when input field is focused
 */
 input[type="color"],
 input[type="date"],
 input[type="datetime"],
 input[type="datetime-local"],
 input[type="email"],
 input[type="month"],
 input[type="number"],
 input[type="password"],
 input[type="search"],
 input[type="tel"],
 input[type="text"],
 input[type="time"],
 input[type="url"],
 input[type="week"],
 textarea,
 select:focus,
 textarea {
   font-size: 16px;
 }
